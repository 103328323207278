// var islands = document.querySelectorAll(".islands")
// var islandNews = document.querySelector(".island-news")


// function revealIslands(e) {
// //   window.addEventListener('turbolinks:load', (event) => {
//       console.log("Hello islands");

const revealIslands = (e) => {
  const islands = document.querySelectorAll(".islands");
  const islandNews = document.querySelector(".island-news");

      islands.forEach(function(island, index) {
          island.addEventListener('mouseenter', function(){
          console.log("hello inside islands");
          if (island.className == "islands lesbos") {
            islandNews.classList.add('news-lesbos');
          } else if (island.className == "islands samos") {
            islandNews.classList.add('news-samos')}
            else if (island.className == "islands chios") {
            islandNews.classList.add('news-chios')}
            else if (island.className == "islands kos") {
            islandNews.classList.add('news-kos')}
            else if (island.className == "islands en_samos") {
            islandNews.classList.add('en_news-samos')}
            else if (island.className == "islands en_chios") {
            islandNews.classList.add('en_news-chios')}
            else if (island.className == "islands en_kos") {
            islandNews.classList.add('en_news-kos')}
            else if (island.className == "islands en_lesbos") {
            islandNews.classList.add('en_news-lesbos')}
        })

          island.addEventListener('mouseleave', function(){
          console.log("hello inside islands");
          if (island.className == "islands lesbos") {
            islandNews.classList.remove('news-lesbos');
          } else if (island.className == "islands samos") {
            islandNews.classList.remove('news-samos')}
            else if (island.className == "islands chios") {
            islandNews.classList.remove('news-chios')}
            else if (island.className == "islands kos") {
            islandNews.classList.remove('news-kos')}
            else if (island.className == "islands en_samos") {
            islandNews.classList.remove('en_news-samos')}
            else if (island.className == "islands en_chios") {
            islandNews.classList.remove('en_news-chios')}
            else if (island.className == "islands en_kos") {
            islandNews.classList.remove('en_news-kos')}
            else if (island.className == "islands en_lesbos") {
            islandNews.classList.remove('en_news-lesbos')}
        })


      })
    }

export { revealIslands };
