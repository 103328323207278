

const revealChart = (e) => {

      const slices = document.querySelectorAll(".slice");
      const chartpopup = document.querySelector(".chart-popup");

      console.log("Hello chart");

      slices.forEach(function(slice, index) {
          slice.addEventListener('mouseenter', function(){
          console.log("hello inside chart");
          if (slice.className == "slice popup-anna slice-one") {
            chartpopup.classList.add('chart-afghanistan');
          } else if (slice.className == "slice popup-anna slice-two") {
            chartpopup.classList.add('chart-syrie')}
            else if (slice.className == "slice popup-anna slice-three") {
            chartpopup.classList.add('chart-pakistan')}
            else if (slice.className == "slice popup-anna slice-four") {
            chartpopup.classList.add('chart-drc')}
            else if (slice.className == "slice popup-anna slice-five") {
            chartpopup.classList.add('chart-bangladesh')}
            else if (slice.className == "slice popup-anna slice-six") {
            chartpopup.classList.add('chart-irak')}
            else if (slice.className == "slice popup-anna slice-seven") {
            chartpopup.classList.add('chart-turquie')}
            else if (slice.className == "slice popup-anna slice-eight") {
            chartpopup.classList.add('chart-albanie')}
            else if (slice.className == "slice popup-anna slice-nine") {
            chartpopup.classList.add('chart-somalie')}
            else if (slice.className == "slice popup-anna slice-ten") {
            chartpopup.classList.add('chart-palestine')}
            else if (slice.className == "slice popup-anna slice-eleven") {
            chartpopup.classList.add('chart-bangladesh')}

            //anglais
            else if (slice.className == "slice popup-anna en_slice-one") {
            chartpopup.classList.add('en_chart-afghanistan')}
            else if (slice.className == "slice popup-anna en_slice-two") {
            chartpopup.classList.add('en_chart-syrie')}
            else if (slice.className == "slice popup-anna en_slice-three") {
            chartpopup.classList.add('en_chart-pakistan')}
            else if (slice.className == "slice popup-anna en_slice-four") {
            chartpopup.classList.add('en_chart-drc')}
            else if (slice.className == "slice popup-anna en_slice-five") {
            chartpopup.classList.add('en_chart-bangladesh')}
            else if (slice.className == "slice popup-anna en_slice-six") {
            chartpopup.classList.add('en_chart-irak')}
            else if (slice.className == "slice popup-anna en_slice-seven") {
            chartpopup.classList.add('en_chart-turquie')}
            else if (slice.className == "slice popup-anna en_slice-eight") {
            chartpopup.classList.add('en_chart-albanie')}
            else if (slice.className == "slice popup-anna en_slice-nine") {
            chartpopup.classList.add('en_chart-somalie')}
            else if (slice.className == "slice popup-anna en_slice-ten") {
            chartpopup.classList.add('en_chart-palestine')}
            else if (slice.className == "slice popup-anna en_slice-eleven") {
            chartpopup.classList.add('en_chart-bangladesh')}
        })



          slice.addEventListener('mouseleave', function(){
          console.log("hello inside chart");
          if (slice.className == "slice popup-anna slice-one") {
            chartpopup.classList.remove('chart-afghanistan');
          } else if (slice.className == "slice popup-anna slice-two") {
            chartpopup.classList.remove('chart-syrie')}
            else if (slice.className == "slice popup-anna slice-three") {
            chartpopup.classList.remove('chart-pakistan')}
            else if (slice.className == "slice popup-anna slice-four") {
            chartpopup.classList.remove('chart-drc')}
            else if (slice.className == "slice popup-anna slice-five") {
            chartpopup.classList.remove('chart-bangladesh')}
            else if (slice.className == "slice popup-anna slice-six") {
            chartpopup.classList.remove('chart-irak')}
            else if (slice.className == "slice popup-anna slice-seven") {
            chartpopup.classList.remove('chart-turquie')}
            else if (slice.className == "slice popup-anna slice-eight") {
            chartpopup.classList.remove('chart-albanie')}
            else if (slice.className == "slice popup-anna slice-nine") {
            chartpopup.classList.remove('chart-somalie')}
            else if (slice.className == "slice popup-anna slice-ten") {
            chartpopup.classList.remove('chart-palestine')}
            else if (slice.className == "slice popup-anna slice-eleven") {
            chartpopup.classList.add('chart-bangladesh')}

            //anglais
            else if (slice.className == "slice popup-anna en_slice-one") {
            chartpopup.classList.remove('en_chart-afghanistan')}
            else if (slice.className == "slice popup-anna en_slice-two") {
            chartpopup.classList.remove('en_chart-syrie')}
            else if (slice.className == "slice popup-anna en_slice-three") {
            chartpopup.classList.remove('en_chart-pakistan')}
            else if (slice.className == "slice popup-anna en_slice-four") {
            chartpopup.classList.remove('en_chart-drc')}
            else if (slice.className == "slice popup-anna en_slice-five") {
            chartpopup.classList.remove('en_chart-bangladesh')}
            else if (slice.className == "slice popup-anna en_slice-six") {
            chartpopup.classList.remove('en_chart-irak')}
            else if (slice.className == "slice popup-anna en_slice-seven") {
            chartpopup.classList.remove('en_chart-turquie')}
            else if (slice.className == "slice popup-anna en_slice-eight") {
            chartpopup.classList.remove('en_chart-albanie')}
            else if (slice.className == "slice popup-anna en_slice-nine") {
            chartpopup.classList.remove('en_chart-somalie')}
            else if (slice.className == "slice popup-anna en_slice-ten") {
            chartpopup.classList.remove('en_chart-palestine')}
            else if (slice.className == "slice popup-anna en_slice-eleven") {
            chartpopup.classList.add('en_chart-bangladesh')}
        })

    });
}


export { revealChart };
